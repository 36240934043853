export const makePhotoURL = (url: string | undefined): string => {
    const env = process.env.REACT_APP_DEV_ENV
    if (env === 'PROD') {
        return url ? url : 'No URL'
    } else {
        const devURL = process.env.REACT_APP_STRAPI_API_BASE_URL_DEV
        return (url && devURL) ? `${devURL?.slice(0, -4)}${url}` : 'No URL'
    }
}

export const getHeaderPhotoURL = () => {
    const playerIndex = Math.floor(Math.random() * (Math.ceil(0) + Math.floor(6)))
    const playerPhotos = [
        '/thecupitals.png',
        '/playerPhotos/backstrom.png',
        '/playerPhotos/carlson-cropped.png',
        '/playerPhotos/holtby.png',
        '/playerPhotos/kuznetsov.png',
        '/playerPhotos/oshie.png',
    ]
    return playerPhotos[playerIndex]
}
import { useTheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Container from '@mui/material/Container'
import { Header } from './Header'
import { Home } from './Home'
import { Footer } from './Footer'
import { Post } from './Post'
import { About } from './About'
import { Paper } from '@mui/material'

export default function Blog() {

  const theme = useTheme()

  const sections = [
    { title: 'Player Profile', url: '#' },
    { title: 'Trades', url: '#' },
    { title: 'The League', url: '#' },
    { title: 'Cup Check', url: '#' },
    { title: 'Prospects', url: '#' },
  ]

  return (
    <CssBaseline>
      <BrowserRouter>
        <Header title="The Cupitals" sections={sections} />
        <Paper sx={{ backgroundColor: theme.palette.background.default, display: 'flex', flexDirection: 'column' }}>
          <Container className='main-container' maxWidth="lg">
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='post/:postId' element={<Post />} />
              <Route path='/about' element={<About />} />
            </Routes>
          </Container>
          <Footer
            title="Follow on Social!"
            description="Never Forget 2018!"
          />
        </Paper>
      </BrowserRouter>
    </CssBaseline>
  )
}
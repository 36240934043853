import { Box, Typography, Link } from '@mui/material'
import { useTheme } from '@mui/material'
import { Helmet } from 'react-helmet-async'

export function About() {

    const theme = useTheme()

    return (
        <>
            <Helmet>
                <title>About</title>
                <meta name="description" content="Some helpful tips in our About Section" />
                <link rel="canonical" href="/about" />
            </Helmet>
            <Box sx={{ height: '75vmin', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', justifyContent: 'center', color: theme.palette.primary.main }}>
                <Link
                    href='mailto:thecupitals@gmail.com'
                    className='header-links'
                    key='email-link'
                    style={{ flexShrink: 0, textDecoration: 'none', minWidth: '170px', color: theme.palette.primary.main }}
                >
                    <Typography textAlign={'center'}>
                        Contact: thecupitals@gmail.com
                    </Typography>
                </Link>
                {/* <Typography>Contact: thecupitals@gmail.com</Typography> */}
                <Typography>Image Credits: Cristiano Siqueira</Typography>
            </Box>
        </>
    )
}
import { Box, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { FeaturedPost } from './FeaturedPost'
import { Link } from 'react-router-dom'
import { PostArea } from './PostArea'
import { Sidebar } from './Sidebar'
import { Helmet } from 'react-helmet-async'

type Props = {}

export const Home = (props: Props) => {

    const theme = useTheme()

    const sections = [
        // { title: 'Player Analytics', url: '#' },
        // { title: 'About', url: '#' },
        // { title: 'Contact', url: '#' },
        { title: 'About', url: '/about' },
        { title: 'New Features Coming Soon!', url: '#' },
    ]

    const sidebar = {
        title: 'About this Blog',
        description:
            `Enjoy the unhinged ramblings of a Software Engineer who can't get enough hockey. 
            I bought 'TheCupitals.com' website 5 minutes after The Capitals won the Stanley Cup in 2018. 
            This page will be a mix of up-to-date feedback on what is going on with the team as well as the occasional flashback to the good old days. 
            If you feel like saying hi, come stop by section 111. Enjoy the hot takes. Comments take place on Twitter.`,
    }

    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="The Cupitals Home Page" />
                <link rel="canonical" href="/" />
                <meta name="twitter:title" content="TheCupitals.com" />
                <meta name="twitter:description" content="The Cupitals - Sufficiently-biased Capitals Blogging" />
                <meta name="twitter:image:src" content="%PUBLIC_URL%/thecupitals.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <main>
                <Box alignContent='center' sx={{ width: '60%', margin: '0 auto', display: 'flex', justifyContent: 'space-around', overflowX: 'auto', padding: 5 }}>
                    {sections.map((section) => (
                        <Link
                            to={section.url}
                            className='header-links'
                            key={section.title}
                            style={{ flexShrink: 0, textDecoration: 'none', minWidth: '170px', color: theme.palette.primary.main }}
                        >
                            <Typography textAlign={'center'}>
                                {section.title}
                            </Typography>
                        </Link>
                    ))}
                </ Box>
                <FeaturedPost />
                <Grid container spacing={5} sx={{ py: 3 }}>
                    <PostArea />
                    <Sidebar
                        title={sidebar.title}
                        description={sidebar.description}
                    />
                </Grid>
            </main>
        </>
    )
}
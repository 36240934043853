import { createContext, useState } from 'react'

export interface ISettingsContext {
    darkTheme: boolean
    toggleDarkTheme: () => void
    sansSerif: boolean
    toggleSansSerif: () => void
    largeText: boolean
    toggleLargeText: () => void
}

const SettingsContext = createContext<ISettingsContext>({
    darkTheme: false,
    toggleDarkTheme: () => { },
    sansSerif: false,
    toggleSansSerif: () => { },
    largeText: false,
    toggleLargeText: () => { },
})

const isDarkFromCache = (): boolean => window.localStorage.getItem("cupitalsDarkTheme") === 'dark'
const isSerifFromCache = (): boolean => window.localStorage.getItem("cupitalsSanSerif") === 'sans'
const isLargeTextFromCache = (): boolean => window.localStorage.getItem("cupitalsLargeText") === 'large'

export const SettingsProvider = ({ children }: any) => {
    const [darkTheme, setDarkTheme] = useState<boolean>(isDarkFromCache())
    const [sansSerif, setSansSerif] = useState<boolean>(isSerifFromCache())
    const [largeText, setLargeText] = useState<boolean>(isLargeTextFromCache())

    const toggleDarkTheme = () => {
        setDarkTheme(!darkTheme)
        window.localStorage.setItem("cupitalsDarkTheme", !darkTheme ? 'dark' : 'light')
    }

    const toggleSansSerif = () => {
        setSansSerif(!sansSerif)
        window.localStorage.setItem("cupitalsSanSerif", !sansSerif ? 'sans' : 'serif')
    }

    const toggleLargeText = () => {
        setLargeText(!largeText)
        window.localStorage.setItem("cupitalsLargeText", !largeText ? 'large' : 'reg')
    }

    return (
        <SettingsContext.Provider value={{ darkTheme, toggleDarkTheme, sansSerif, toggleSansSerif, largeText, toggleLargeText }}>
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
import axios from 'axios'

//Tokens
const devToken = process.env.REACT_APP_BEARER_TOKEN_DEV
const prodToken = process.env.REACT_APP_BEARER_TOKEN_PROD

//URLs
const devURL = process.env.REACT_APP_STRAPI_API_BASE_URL_DEV
const prodURL = process.env.REACT_APP_STRAPI_API_BASE_URL_PROD

//Set Tokens & URLs
const env = process.env.REACT_APP_DEV_ENV
const bearerToken = env === 'PROD' ? prodToken : devToken
const baseURL = env === 'PROD' ? prodURL : devURL

const strapi = axios.create({
    baseURL: baseURL,
    timeout: 3000,
    headers: { Authorization: `Bearer ${bearerToken}` },
});

export const getPosts = async () => {
    try {
        // Without populate param, strapi drops media/assets
        const res = await strapi.get('/posts?sort[0]=id:desc&populate=*')
        return res.data.data
    }
    catch (e) {
        throw e
    }
}

export const getPostByID = async (id: string): Promise<IPost> => {
    try {
        // Without populate param, strapi drops media/assets
        const res = await strapi.get(`/posts/${id}?populate=*`)
        return res.data.data
    }
    catch (e) {
        throw e
    }
}

export const getFeaturedPost = async () => {
    try {
        // Without populate param, strapi drops media/assets
        const res = await strapi.get(`/posts/${process.env.REACT_APP_FEATURED_POST_ID}?populate=*`)
        return res.data.data
    }
    catch (e) {
        throw e
    }
}
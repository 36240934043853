import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPostByID } from "../api/strapi";
import ReactMarkdown from "markdown-to-jsx";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import TwitterIcon from "@mui/icons-material/Twitter";
import { makePhotoURL } from "../utils/photos";
import { Author } from "./Author";
import { Helmet } from "react-helmet-async";
import { Tweet } from "react-tweet";
import SettingsContext from "./context/Settings";

function MarkdownListItem(props: any) {
  return <Box component="li" sx={{ mt: 1, typography: "body1" }} {...props} />;
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h3",
        component: "h1",
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: "h4", component: "h4" },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "caption",
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: Link },
    li: {
      component: MarkdownListItem,
    },
  },
};

export function Post() {
  const params = useParams();
  const [post, setPost] = useState<IPost | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [tweetTheme, setTweetTheme] = useState("light");
  const theme = useTheme();
  const { darkTheme } = useContext(SettingsContext);

  const getTweetLink = () => {
    return `https://x.com/TheCupitals/status/${post?.attributes.xPostLink}?s=20`;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        if (params.postId) {
          const data = await getPostByID(params.postId);
          setError(undefined);
          setPost(data);
        }
      } catch (e: any) {
        setError(e.toString());
        setPost(undefined);
      }
    };
    getData();
  }, [setPost, setError, useParams, params, getPostByID]);

  useEffect(() => {
    if (darkTheme) {
      setTweetTheme("dark");
    } else {
      setTweetTheme("light");
    }
  }, [darkTheme]);

  return (
    <>
      {post && (
        <Helmet>
          <title>{post.attributes.title}</title>
          <meta name="description" content={post.attributes.description} />
          <link rel="canonical" href={`/post/${post.id}`} />
          <meta name="twitter:title" content="TheCupitals.com" />
          <meta
            name="twitter:description"
            content={post.attributes.description}
          />
          <meta
            name="twitter:image:src"
            content={makePhotoURL(post.attributes.image.data.attributes.url)}
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      )}
      <Container
        maxWidth="md"
        style={{
          paddingBottom: "50px",
          marginTop: "50px",
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        <Box>
          {post && (
            <Card>
              <CardMedia
                sx={{
                  height: "50vmin",
                  backgroundPosition: "top center",
                  backgroundSize: "cover",
                }}
                height="100"
                component="img"
                image={makePhotoURL(post.attributes.image.data.attributes.url)}
                alt={post.attributes.image.data.attributes.alternativeText}
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 1.5,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography align="center" variant="h3" sx={{ mt: 5 }}>
                    {post.attributes.title}
                  </Typography>
                  <Typography align="center" variant="subtitle1" sx={{ m: 3 }}>
                    {post.attributes.subtitle}
                  </Typography>
                  <Author post={post} small={false} />
                  <Link
                    href={post.attributes.xPostLink ? getTweetLink() : "#"}
                    target="_blank"
                    key={post.id}
                    sx={{
                      mt: 3,
                      "& .MuiSvgIcon-root": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    <TwitterIcon fontSize="large" />
                  </Link>
                </Box>
                <Divider
                  variant="inset"
                  sx={{ width: "75%", margin: "0 auto", mt: 3 }}
                />
                <ReactMarkdown options={options}>
                  {post.attributes.content}
                </ReactMarkdown>
                {post.attributes.xPostLink && (
                  <div style={{ margin: "0 auto" }} data-theme={tweetTheme}>
                    <Tweet id={post.attributes.xPostLink} />
                  </div>
                )}
              </CardContent>
            </Card>
          )}
          {error && error}
        </Box>
      </Container>
    </>
  );
}
